<template>
    <div class="answer-container">
        <div class="answer-banner">
            <div class="answer-list">
                <div class="answer-button">开始答题</div>
                <div style="color: #fff">共{{ current + 1 }} / {{questionNumber}}题</div>
            </div>
            <van-divider hairline style="margin: 0" />
            <div class="answer-title">
                <div class="answer-radio"></div>
                {{ question }}
            </div>
        </div>
        <div class="answer-content">
            <ul>
                <li
                    :class="[
                        item.includes(currentAnswer) ? 'active' : '',
                        currentIndex == index ? 'correct' : '',
                        'answer-item',
                    ]"
                    v-for="(item, index) in answer"
                    :key="index"
                    @click="choseAnswer(item, index)"
                >
                    {{ item }}
                    <van-icon
                        name="success"
                        color="#fff"
                        size="24"
                        v-if="currentIndex == index"
                    />
                    <van-icon
                        name="cross"
                        color="#231f20"
                        size="24"
                        v-if="item.includes(currentAnswer)"
                    />
                </li>
            </ul>
        </div>
        <van-popup v-model="show" round class="popup-container" closeable>
            <div>
                <!-- <van-image
                    width="150px"
                    height="120px"
                    radius="0"
                    :src="
                        punchStatus
                            ? 'https://img01.yzcdn.cn/vant/cat.jpeg'
                            : 'https://img01.yzcdn.cn/vant/cat.jpeg'
                    "
                    class="u-m-t-30"
                /> -->
                <van-icon
                    name="invitation"
                    size="120"
                    :color="punchStatus ? '#B72E32' : '#999'"
                />
                <div class="popup-title u-m-t-20">
                    {{
                        punchStatus
                            ? "恭喜你，通关成功～"
                            : "很抱歉，通关失败～"
                    }}
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { getQuestionList, saveForUser } from "@/service";
export default {
    data() {
        return {
            answer: [],
            question: "",
            questionList: [],
            correct: undefined,
            current: 0,
            punchStatus: false,
            show: false,
            currentIndex: -1,
            currentAnswer: undefined,
            questionNumber: 0,
            questionId: "",
        };
    },
    watch: {
        current() {
            this.question = this.questionList[this.current].question;
            this.answer = JSON.parse(this.questionList[this.current].answer);
            this.correct = this.questionList[this.current].correct;
        },
    },
    mounted() {
        this.getQuestionList();
    },
    methods: {
        async getQuestionList() {
            const { id = "" } = this.$route.query;
            const params = {
                commonId: id,
                pageNo: 1,
                pageSize: 1000,
            };
            const { data } = await getQuestionList(params);
            this.questionId = data.list[0].commonId;
            this.questionList = data.list;
            this.questionNumber = data.list.length;
            this.question = data.list[this.current].question;
            this.answer = JSON.parse(data.list[this.current].answer);
            this.correct = data.list[this.current].correct;
            console.log("")
        },
        async saveForUser() {
            const params = {
                questionId: this.commonId || '5',
                result: "1",
            };
            const { data } = await saveForUser(params);
        },
        async choseAnswer(item, index) {
            if (item.includes(this.correct)) {
                this.currentIndex = index;
                this.currentAnswer = undefined;
                if (this.current == this.questionNumber - 1) {
                    this.punchStatus = true;
                    this.show = true;
                    await this.saveForUser();
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                    return false;
                }
                setTimeout(() => {
                    this.current = this.current + 1;
                    this.$nextTick(() => {
                        this.currentIndex = -1;
                    });
                }, 800);

                return false;
            }
            this.currentAnswer = item;
        },
    },
};
</script>

<style lang="scss">
@import "@/style/mixin";
@import "@/style/_varibles";

.answer-container {
    .answer-banner {
        height: 300px;
        padding: 0 40px;
        background: #b72e32;
        .answer-list {
            width: 100%;
            height: 114px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            color: #fff;
            .answer-button {
                width: 160px;
                height: 50px;
                background: #e9a228;
                border-radius: 25px;
                line-height: 50px;
                text-align: center;
                color: #fff;
            }
        }
        .answer-title {
            font-size: 28px;
            color: #fff;
            padding-top: 38px;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        .answer-radio {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border-radius: 50%;
            margin-right: 20px;
        }
    }
    .answer-content {
        padding: 120px 38px;
        .answer-item {
            width: 100%;
            height: 100px;
            background: #ffffff;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
            border-radius: 20px;
            font-size: 28px;
            color: #231f20;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 40px;
            &.active {
                background: #ccc;
                color: #fff;
            }
            &.correct {
                background: #b72e32;
                color: #fff;
            }
        }
    }
    .popup-container {
        @include fj(center);
        align-items: center;
        width: 676px;
        height: 460px;
        background: #ffffff;
        border-radius: 40px;
        text-align: center;
        .popup-title {
            @include sc(22px, $--color-text-regular);
        }
    }
}
</style>